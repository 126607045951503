<template>
    <div class="select-model">
        <div class="search-item">
            <input placeholder="请输入搜索内容…" v-model="keyword" @keyup.enter="search">
            <span class="search-btn iconfont" @click="search">&#xe682;</span>
        </div>
        <div class="select-item">
            <div class="tag">
                已选
            </div>
            <span
                class="have-item"
                v-for="item in selectList"
                :key="item.id"
                @click="handleChangeSelected(item)"
            >
                {{ item.smallclass_name }}<i class="close-btn iconfont">&#xe67d;</i>
            </span>
        </div>
        <div class="model-contain" v-loading="loading">
            <h1 class="title">
                <i class="iconfont">&#xe6fc;</i>可选附加型号
            </h1>
            <div class="model-list">
                <div
                    :class="['model-item',item.selected?'selected':'']"
                    v-for="(item,index) in modelList"
                    :key="index"
                    :style="{color:item.color,background:hexToRgba(item.color,0.01) }"
                    @click="handleChangeSelected(item)"
                >
                    <span class="select-tag iconfont">&#xe605;</span>
                    <div class="name" style="color:#333333;">
                        {{ item.smallclass_name }}
                    </div>
                    <div class="info">
                        <span class="type"><i class="type-tag" :style="{background:'#979EA7'}">子</i>{{ item.subclass_name }}</span>
                        <span class="price" v-if="item.attr_price!=''"><i class="price-tag iconfont">&#xe6fb;</i>{{ item.attr_price }}元/kg</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
            <span v-if="extr.sureCallback" @click="handleMixSelected">组合选择</span>
            <span v-if="extr.multipleCallBack" @click="handleBatchSelected">批量选择</span>
        </div>
    </div>
</template>
<script>
const itemColor = ['#0197F3', '#70B00A', '#5F5EE2', '#C28E00', '#00B18A']; // 附加型号颜色
const colorObj = {};
export default {
    name: 'select-model',
    data() {
        return {
            // 加载中状态
            loading: false,
            area_id: '',
            keyword: '', // 搜索关键字
            modelList: [], // 数据列表
            selectList: [], // 已选择列表
        };
    },
    props: {
        extr: Object,
    },
    created() {
        // 测试 'cfa2004180002'
        this.area_id = this.extr.area_id || '';
        this.getList();
    },
    watch: {},
    methods: {
        async getList() {
            this.loading = true;
            const list = await this.getModelList(this.area_id);
            this.modelList = this.initColor(list);
            this.loading = false;
        },
        search() {
            this.getList();
        },
        // 列表项颜色生成规则
        initColor(list) {
            let colorIndex = 0;
            return list.map(item => {
                if (!colorObj[item.subclass_code]) {
                    if (!itemColor[colorIndex]) {colorIndex = 0;}
                    colorObj[item.subclass_code] = itemColor[colorIndex];
                    colorIndex++;
                }
                item.color = colorObj[item.subclass_code];
                item.selected = false;
                return item;
            });
        },
        // 选择附加型号 或者移除
        handleChangeSelected(item) {
            if (this.selectList.indexOf(item) === -1) {
                item.selected = true;
                this.selectList.push(item);
            } else {
                item.selected = false;
                this.selectList.splice(this.selectList.indexOf(item), 1);
            }
        },
        // 组合选择
        handleMixSelected() {
            const haveData = this.returnSelectData();
            if (haveData) {
                this.extr.sureCallback(this.selectList);
            }
            this.handleChooseEvent();
        },
        // 批量选择
        handleBatchSelected() {
            const haveData = this.returnSelectData();
            if (haveData) {
                this.extr.multipleCallBack(this.selectList);
            }
            this.handleChooseEvent();
        },
        returnSelectData() {
            if (this.selectList.length < 1) {
                this.$message({
                    message: '请先选择附加型号！',
                    type: 'warning',
                });
                return false;
            }
            this.$parent.hide();
            return true;

        },
        // 获取附加类型列表
        getModelList(area_id) {
            return this.$axios.post('/interfaceApi/sale/contract_price/get_additional_list?area_id=' + area_id + '&keyword=' + this.keyword);
        },
        handleChooseEvent() {
            if (this.selectList.length > 0) {
                const code501 = [];
                const codeF502 = [];
                this.selectList.forEach(item => {
                    if (item.bigclass_code === '501') {
                        code501.push(item.smallclass_code);
                    } else if (item.bigclass_code === 'F5-02') {
                        codeF502.push(item.smallclass_code);
                    }
                });
                if (code501.length > 0) {
                    this.$axios.put('/interfaceApi/BaseInfo/dictionary_config/modify_user_count?code=' + code501.join());
                }
                if (codeF502.length > 0) {
                    this.$axios.put('/interfaceApi/BaseInfo/businessgroup_config/modify_user_count?code=' + codeF502.join());
                }
            }
        },
        hexToRgba(hex, opacity) {
            return hex && hex.replace(/\s+/g, '').length === 7 ? 'rgba(' + Number('0x' + hex.slice(1, 3)) + ','
                + Number('0x' + hex.slice(3, 5)) + ','
                + Number('0x' + hex.slice(5, 7)) + ',' + opacity + ')' : '';
        },
        // 处理已经选择的项目
        initSeletedItem(list) {
            const selectCode = [];
            (this.extr.selectData || []).forEach(selectItem => {
                selectCode.push(selectItem.additional_model_code);
            });
            return list.map(item => {
                if (selectCode.indexOf(item.smallclass_code) !== -1) {
                    item.selected = true;
                    this.handleChangeSelected(item);
                }
                return item;
            });
        },
    },
};
</script>
<style scoped lang="stylus">
.select-model
    height 100%
    padding 0.2rem
    .search-item
        position relative
        input
            width 100%
            height: 0.48rem;
            background: #FFFFFF;
            border-radius: 2px;
            border: 1px solid #D7D7D7;
            padding-left 0.1rem;
        .search-btn
            position absolute
            right 0;
            top 0;
            width: 0.8rem;
            height: 0.48rem;
            background: linear-gradient(270deg, #1577D2 0%, #3E79C5 100%);
            border-radius: 0rem 0.04rem 0.04rem 0rem
            font-size 0.24rem
            color #fff
            display flex
            justify-content center
            align-items center
            cursor: pointer;
    .select-item
        display flex
        align-items center
        flex-wrap nowrap
        width: 100%;
        height: 0.6rem;
        background: #FFFAF7;
        border: 1px solid #FFCEAE;
        padding-left 0.8rem;
        overflow hidden
        position relative
        margin-top 0.2rem;
        overflow-x auto
        .tag
            display flex
            justify-content center
            align-items flex-end
            padding-bottom 0.03rem
            width: 0.8rem;
            height: 0.6rem;
            background: #FF8433;
            font-size: 0.14rem;
            color: #FFFFFF;
            transform: rotate(-50deg);
            position: absolute;
            left: -0.37rem;
            top: -0.2rem;
        .have-item
            display flex
            justify-content space-between
            align-items center
            padding 0 0.08rem;
            min-width: 1rem;
            height: 0.36rem;
            background: #FFFAF7;
            border: 1px solid #FFCEAE;
            font-size: 0.14rem;
            color: #FF8433;
            margin 0 0.1rem;
            cursor pointer
            .close-btn
                display flex
                width 0.12rem;
                height 0.12rem;
                font-size 0.12rem
                margin-left 0.05rem;
            &:hover
                background: #FFF1F0;
                border: 1px solid #FF3134;
                color: #FF3134;
    .model-contain
        height calc(100% - 2rem)
        overflow hidden
        .title
            padding 0.2rem 0 0.1rem
            font-size: 0.2rem;
            font-weight: 500;
            color: #007FF5;
            i
                font-size: 0.2rem;
                margin-right 0.1rem
         .model-list
             display flex
             flex-wrap wrap
             flex-direction row
             height: 100%;
             padding-bottom 0.8rem;
             overflow-y: auto;
            .model-item
                position relative
                margin 0.1rem
                width: 2.50rem;
                height: 0.85rem;
                padding 0.18rem
                background: rgba(230, 247, 255, 0.3);
                border: 1px solid;
                cursor pointer
                .name
                    font-size 0.16rem
                    font-weight bold
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                .info
                    display flex
                    padding-top 0.12rem
                    font-size 0.14rem
                    color #979EA7
                    .type
                        display flex
                        align-items center
                        margin-right 0.4rem
                        color #666666
                        .type-tag
                            display inline-block
                            width: 0.16rem;
                            height: 0.16rem;
                            text-align center
                            line-height 0.16rem;
                            border-radius 50%
                            color #fff;
                            font-size 0.1rem
                            margin-right 0.05rem
                    .price
                        .price-tag
                            font-size 0.14rem
                            margin-right 0.05rem
                .select-tag
                    position absolute
                    right 0.05rem;
                    top 0.05rem;
                    font-size 0.22rem
                    opacity 0.8
                &.selected
                    $color = #FF8433
                    background: #FFFAF7;
                    box-shadow: 0rem 0.02rem 0.08rem 0rem rgba(137, 92, 1, 0.4);
                    border: 0.02rem solid $color;
                    .name
                        color $color
                    .info
                        .type
                            color $color
                            .type-tag
                                background  $color !important
                        .price
                            color $color
                    .select-tag
                        color $color
                        opacity 1
    .footer
        position absolute
        left 0
        bottom 0
        display flex
        justify-content center
        align-items center
        width 100%;
        height: 0.9rem;
        background: #FFFFFF;
        box-shadow: 0.02rem 0rem 0.1rem 0rem rgba(0, 0, 0, 0.3);
        span
            display flex
            justify-content center
            align-items center
            margin 0 0.4rem;
            width: 2.6rem;
            height: 0.5rem;
            background: linear-gradient(225deg, #55A7FE 0%, #3E79C5 100%);
            border-radius: 1px;
            font-size: 0.2rem;
            color #fff
            cursor pointer
</style>
